import './App.css';
import React, { setGlobal} from 'reactn';

import Player from './components/Player.js'
import './custom.scss';
import { withAuthenticator} from "@aws-amplify/ui-react";
import { Auth } from '@aws-amplify/auth';
import { Amplify } from '@aws-amplify/core'
import { DataStore } from "@aws-amplify/datastore";
import awsmobile from './aws-exports';
import ApiHelper from './components/ApiHelper.js';
const api = new ApiHelper();
Amplify.configure(awsmobile);
class App extends React.Component { 
 
    
    getUser = async() =>{
        if (!this.global.user){
            let user = await Auth.currentUserInfo();
            user.isAdmin = user.attributes["custom:Admin"] === "1";
            setGlobal({ 
                "user" : user
            }) 
            return user
        }
    }     
    getPlaylists = async() => {
        if (this.global.user) {
            let artist_playlists = await api.getArtistPlaylists(this.global.user);
            let genre_playlists = await api.getGenrePlaylists(this.global.user);
            let auto_playlists = await api.getAutoPlaylists(this.global.user);
            setGlobal({ 
                artist_playlists: artist_playlists,
                genre_playlists: genre_playlists,
                auto_playlists: auto_playlists
            }) 
            this.forceUpdate();
             
        }    
    }  
    getArtists = async() => {
        let artists = await api.getArtists();
        setGlobal({
            all_artists : artists
        })
        this.forceUpdate();
        
    } 
    getGenres = async() => {
        let genres = await api.getGenres(this.global.user);
        setGlobal({
            all_genres : genres
        })
        this.forceUpdate();
        
    } 
    componentDidMount() { 
        
        
        this.getUser().then(() => {
            this.getArtists();
            this.getPlaylists();
            this.getGenres();            
        })    
               
    }  
    render() {     
        
        return (
            <div className="tss">
                <div className="container">                    
                    <div className="row main">
                        <Player user={this.global.user} all_artists={this.global.all_artists}/>                
                    </div>
                    <div className="row footer"></div>
                </div>        
            </div>
        )
    }
}

export default withAuthenticator(App);