import React from 'reactn';
import Tags from './Tags.js'
import ApiHelper from './ApiHelper.js';
import { DataStore } from "@aws-amplify/datastore";
import { Video } from "../models";
const api = new ApiHelper();
class Reactions extends React.Component{
    state = {
          skip: false,
          ban: false,
          ban_artist: false,
          like: false,
          like_artist: false          
    }

    toggleReaction = (reaction) => {
        let status = this.state;
        status[reaction] = !status[reaction]
        console.log(status)
        this.setState(status)
        this.logActivity(reaction)
    }    
    logActivity = async (activity) => {        
        api.logActivity(this.global.user, this.props.video, activity);
        let activity_map = {
            'like' : 'video_like',
            'ban' : 'video_ban',
            'unlike': 'video_like',
            'unban' : 'video_ban',
            'like_artist' : 'artist_like',
            'ban_artist' : 'artist_ban',
            'unlike_artist': 'artist_like',
            'unban_artist' : 'artist_ban'
        }
        let activity_status = !activity.includes('un')
        if (activity_map.hasOwnProperty(activity)){
            DataStore.save(
                Video.copyOf(this.props.video, updated => {
                    updated[activity_map[activity]] = activity_status.toString();              
                })
            ); 
        } 
        if(this.props.video.currentSong && (activity.includes('ban') || activity.includes('skip'))){            
            this.props.changeVideo();
        }
                 
             
    }
    render(){
      
        return (
            <div>
                <ul className="reactions">
                    <li><img src="icons/skip.png" alt="Skip Song" onClick={this.toggleReaction.bind(this, "skip")}/></li>                
                    <li><img src="icons/ban.png" alt="Ban Song" onClick={this.toggleReaction.bind(this, "ban")}/></li>
                    <li><img src="icons/ban-artist.png" alt="Ban Artist" onClick={this.toggleReaction.bind(this, "ban_artist")} className={(this.props.video && this.props.video.fk_artist_id ? "" : "disabled")}/></li>
                    <li><img src="icons/like.png" alt="Like Song" onClick={this.toggleReaction.bind(this, "like")}/></li>
                    <li><img src="icons/like-artist.png" alt="Like Artist" onClick={this.toggleReaction.bind(this, "like_artist")} className={(this.props.video && this.props.video.fk_artist_id ? "" : "disabled")}/></li>

                </ul>  
                { this.global.user && this.global.user.isAdmin ? 
                    <ul className="admin-buttons">
                        <li><button onClick={this.toggleReaction.bind(this, "globally_ban")}>Globally Ban</button></li> 
                    </ul> :
                    <span/>
                }    
                <Tags video={this.props.video}/> 
            </div>
        )
    }
}
export default Reactions;