import React, {setGlobal} from 'reactn'; 
import Button from 'react-bootstrap/Button';
import { DataStore } from "@aws-amplify/datastore";
import { UserSettings } from "../models/index.js";
const tags_list = ['xmas', 'halloween', 'irish', 'summer', 'drinking', 'driving', 'tennessee'];

class ListTag extends React.Component{
    state = {
          playing: false,
    }

    
    toggleTag = (item, e) => {        
        e.stopPropagation();
        let settings = this.global.settings;
        let playing = !this.state.playing;
        this.setState({
            playing: playing
        });
        let playing_tag = item
        if (!playing){
            playing_tag = null
        }
        DataStore.save(
            UserSettings.copyOf(settings, updated => {
                updated.selected_genres = [];
            })
        );        
        let genre_playlists = this.global.genre_playlists;
        genre_playlists.forEach(function(el) {
            el.playing = false;
            if (el.id === item.id) {
                el.playing = playing;
            }
        })
        let artist_playlists = this.global.artist_playlists;
        artist_playlists.forEach(function(el) {
            el.playing = false;
            if (el.id === item.id) {
                el.playing = playing;
            }
        })
        let auto_playlists = this.global.auto_playlists;
        auto_playlists.forEach(function(el) {
            el.playing = false;
            if (el.fk_seed_id === item.fk_seed_id) {
                el.playing = playing;
            }
        })            
        setGlobal({            
            "playing_genres" : [],
            "playing_tag": playing_tag,
            "genre_playlists": genre_playlists,
            "artist_playlists": artist_playlists,
            "auto_playlists": auto_playlists
        })
        this.props.fetchVideos(true);    
    }
    
    
    
    render(){      
        let list_items = <div/>  

        list_items = tags_list.map((tag) =>
            <li className="playlist-list-item-container" key={tag} >
                <div className="playlist-list-item">                
                    <div className="playlist-list-item-details">                                
                        <div className="playlist-list-item-name">
                            <p>{tag}</p>
                            <div className="playlist-list-item-playlist-button">{this.global.playing_tag === tag ? <Button onClick={this.toggleTag.bind(this, tag)} variant="primary">Stop</Button> : <Button onClick={this.toggleTag.bind(this, tag)} variant="dark">Play</Button>}</div>
                
                        </div>
                        
                    </div>
                    </div>
                </li>
        );

        return (
            <div>               
                <ul className="playist-list">{list_items}</ul>    
            </div>    
        )
    }
}
export default ListTag;