import React from 'reactn'; 
import Modal from "react-bootstrap/Modal";
import VideoComponent from './VideoComponent';
import { DataStore } from "@aws-amplify/datastore";
import { Video } from "../models";
import ApiHelper from './ApiHelper.js';
const api = new ApiHelper();
const VideoModal = (props) => {    
     
    return(
                <Modal show={props.visible} onHide={ props.toggleModal } className="tss-modal">
                    { props.item ? 
                        <div>
                            <Modal.Header>
                                <Modal.Title>{props.item.title}</Modal.Title>
                            </Modal.Header>
                            <div className="video-modal">
                                <VideoComponent item={props.item} all_artists={props.all_artists}/>
                            </div>
                            <Modal.Footer>
                                <button className="btn-secondary" onClick={props.toggleModal}>Close</button>
                            </Modal.Footer>
                        </div> : <div/>
                    }
                </Modal>

    )
}
class ListVideo extends React.Component{
    state = {
          active: false,
          item: {}
    }

    toggleModal = (item) => {
        let active = this.state.active;
        console.log(this)
        this.setState({
            active: !active,
            item: item
        })
    }  
    logActivity = async(activity, item) => {        
        api.logActivity(this.global.user, item, activity);
        let activity_map = {
            'like' : 'video_like',
            'ban' : 'video_ban',
            'unlike': 'video_like',
            'unban' : 'video_ban',
            'like_artist' : 'artist_like',
            'ban_artist' : 'artist_ban',
            'unlike_artist': 'artist_like',
            'unban_artist' : 'artist_ban'
        }
        let activity_status = !activity.includes('un')
        if (activity_map.hasOwnProperty(activity)){
            await DataStore.save(
                Video.copyOf(this.props.item, updated => {
                    updated[activity_map[activity]] = activity_status.toString();              
                })
            ); 
        }           

    }  
    render(){
        let list_items = (this.props.items || []).map((item) =>
            <li className="video-list-item-container" key={item.fk_video_id} >
                <div className="like-checks list-view">
                        {item.video_ban === 'true' ? <img src="icons/ban.png" onClick={this.logActivity.bind(this, 'unban', item)} alt="banned_video" /> : <div/> } 
                        {item.video_like === 'true' ? <img src="icons/like.png"  onClick={this.logActivity.bind(this,'unlike', item)} alt="liked_video"/> : <div/> } 
                        {item.artist_like === 'true' ? <img src="icons/like-artist.png"  onClick={this.logActivity.bind(this,'unlike_artist', item)} alt="liked_artist"/> : <div/> } 
                        {item.artist_ban === 'true' ? <img src="icons/ban-artist.png"  onClick={this.logActivity.bind(this, 'unban_artist', item)} alt="banned_artist"/> : <div/> }
                </div> 
                <div className="video-list-item"  style={{backgroundImage: "url(https://i.ytimg.com/vi/" + item.youtube_id + "/default.jpg)"}}> 
                                  
                    <div className="video-list-item-details" onClick={this.toggleModal.bind(this, item)}>
                                           
                        <div className="video-list-item-name" >
                            <p>{item.youtube_name}</p>                            
                        </div>
                        
                    </div>
                    </div>
                
            </li>
        );
        return (
            <div>
                <VideoModal toggleModal={this.toggleModal} visible={this.state.active} item={this.state.item} all_artists={this.props.all_artists}/>
                <ul className="playist-list">{list_items}</ul>   
            </div>     
        )
    }
}
export default ListVideo;