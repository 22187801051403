// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Video, UserSettings } = initSchema(schema);

export {
  Video,
  UserSettings
};