import React, { setGlobal } from 'reactn';
import { DataStore } from "@aws-amplify/datastore";
import { UserSettings } from "../models";
import ApiHelper from "./ApiHelper"
const api = new ApiHelper();
const languages = ['Afrikaans',
'Albanian',
'Amharic',
'Arabic',
'Armenian',
'Assamese',
'Azerbaijani',
'Bashkir',
'Basque',
'Belarusian',
'Bengali',
'Bosnian',
'Breton',
'Bulgarian',
'Catalan',
'Chinese',
'Croatian',
'Czech',
'Danish',
'Dutch',
'English',
'Estonian',
'Faroese',
'Finnish',
'French',
'Galician',
'Georgian',
'German',
'Greek',
'Gujarati',
'Haitian creole',
'Hausa',
'Hawaiian',
'Hebrew',
'Hindi',
'Hungarian',
'Icelandic',
'Indonesian',
'Italian',
'Japanese',
'Javanese',
'Kannada',
'Kazakh',
'Khmer',
'Korean',
'Lao',
'Latin',
'Latvian',
'Lingala',
'Lithuanian',
'Luxembourgish',
'Macedonian',
'Malagasy',
'Malay',
'Malayalam',
'Maltese',
'Maori',
'Marathi',
'Mongolian',
'Myanmar',
'Nepali',
'Norwegian',
'Nynorsk',
'Occitan',
'Pashto',
'Persian',
'Polish',
'Portuguese',
'Punjabi',
'Romanian',
'Russian',
'Sanskrit',
'Serbian',
'Shona',
'Sindhi',
'Sinhala',
'Slovak',
'Slovenian',
'Somali',
'Spanish',
'Sundanese',
'Swahili',
'Swedish',
'Tagalog',
'Tajik',
'Tamil',
'Tatar',
'Telugu',
'Thai',
'Tibetan',
'Turkish',
'Turkmen',
'Ukrainian',
'Urdu',
'Uzbek',
'Vietnamese',
'Welsh',
'Yiddish',
'Yoruba', 'None']
const genres = ["Children's music",
"Christian music",
"Classical music",
"Country",
"Electronic music",
"Hip hop music",
"Independent music",
"Jazz",
"Miscellaneous Music",
"Music",
"Music of Asia",
"Music of Latin America",
"Pop music",
"Reggae",
"Rhythm and blues",
"Rock music",
"Soul music"]
const delay = ms => new Promise(res => setTimeout(res, ms));


class Settings extends React.Component{
    state = {
        "fetched": false,
        "artistLikeWeight" : 5,
        "songLikeWeight" : 5,
        "artistNewnessWeight" : 5,
        "songNewnessWeight": 5,
        "min_days_between_play": 120,
        "minDB": -15,
        "maxDB": -10,
        "allowedGenres" : genres
    } 
    

    updateSettings = async() => {
        const settings_list = await DataStore.query(UserSettings, (c) => c.cognito_id.eq(this.global.user.id))
        const settings = settings_list.shift()
        let selected_genres = []
        let seg = settings.selected_genres || []
        seg.forEach(function(el) {
            selected_genres.push({ 'id': el })
        });
        setGlobal({
            "settings" : settings,
            "playing_genres" : selected_genres
        })
        this.setState(settings);  
           
        
        
    }
    saveSettings = async() => {
        let settings = this.state;
        settings.artistLikeWeight = parseInt(document.getElementById("artistLikeWeight").value);
        settings.songLikeWeight = parseInt(document.getElementById("songLikeWeight").value);
        settings.artistNewnessWeight = parseInt(document.getElementById("artistNewnessWeight").value);
        settings.songNewnessWeight = parseInt(document.getElementById("songNewnessWeight").value);
        settings.min_days_between_play = parseInt(document.getElementById("min_days_between_play").value);        
        settings.minDB = parseInt(document.getElementById("minDB").value);
        settings.maxDB = parseInt(document.getElementById("maxDB").value);
        if(settings.maxDB < settings.minDB){
            settings.maxDB = parseInt(document.getElementById("minDB").value);
            settings.minDB = parseInt(document.getElementById("maxDB").value);
        }

        let checkboxes = document.getElementsByName('genre');
        let genres_list = []
        for (var i = 0, n = checkboxes.length; i < n; i++) {
            if (checkboxes[i].checked) {
                genres_list.push(checkboxes[i].id)

            }
        }
        settings.allowedGenres = genres_list    
        let lcheckboxes = document.getElementsByName('language');
        let languages_list = []
        for (var i = 0, n = lcheckboxes.length; i < n; i++) {
            if (lcheckboxes[i].checked) {
                languages_list.push(lcheckboxes[i].id)

            }
        }
        settings.allowedLanguages = languages_list;        
        const old_settings_list = await DataStore.query(UserSettings, (c) => c.cognito_id.eq(this.global.user.id));
        const old_settings = old_settings_list[0]      
        await DataStore.save(UserSettings.copyOf(old_settings, new_settings => {
            new_settings.artistLikeWeight = settings.artistLikeWeight;
            new_settings.songLikeWeight = settings.songLikeWeight;
            new_settings.artistNewnessWeight = settings.artistNewnessWeight;
            new_settings.songNewnessWeight = settings.songNewnessWeight ;
            new_settings.min_days_between_play = settings.min_days_between_play;
            new_settings.allowedGenres = genres_list;
            new_settings.allowedLanguages = languages_list;            
            new_settings.minDB = settings.minDB;
            new_settings.maxDB = settings.maxDB;
        }));
        await api.setSettings(this.global.user, settings);
        this.props.fetchVideos(true);
        
        
    }
    getSettings = async(user) => {      
        if(user && !this.state.fetched ){
            let settings_list = await DataStore.query(UserSettings, (c) => c.cognito_id.eq(user.id));
            if (!settings_list || settings_list.length === 0) {
                let r = await api.getSettings(user);
                if (r !== "failed" && r.length > 0) {
                    r[0].allowedGenres = JSON.parse(r[0].allowedGenres);
                    r[0].allowedLanguages = JSON.parse(r[0].allowedLanguages|| ["English", "None"]);
                    r[0].minDB = -15;
                    r[0].maxDB = -10;
                    r[0].selected_genres= [];
                    await DataStore.save(
                        new UserSettings(r[0])
                    );
                }
                settings_list = [r];
            }
            while (settings_list.length > 1) {
                await DataStore.delete(UserSettings, s => s.id.eq(settings_list[0].id));
                settings_list.pop(0)
            } 
            
            

            this.setState({fetched: true})   
            this.setState(settings_list[0])  
            let selected_genres = []
            let seg = settings_list[0].selected_genres || []
            seg.forEach(function(el) {
                selected_genres.push({ 'id': el })
            });
            setGlobal({
                "settings" : settings_list[0],
                "playing_genres" : selected_genres
            })
                     
        }        

    }   
    componentDidMount = async() =>{
        const userSubscription = DataStore.observe(UserSettings).subscribe(msg => {
            this.updateSettings()
        });
        while(true){
            if (this.global.user){
                this.getSettings(this.global.user); 
                break;
            } else{
                await delay(1000);
            }
        }
        
    }
    render(){        
          
        const {artistLikeWeight, songLikeWeight, artistNewnessWeight, songNewnessWeight, min_days_between_play, minDB, maxDB, allowedGenres, allowedLanguages} = this.state;    
        return (
            <div className="settings">
                <div className="settings-save"><button type="button" className="btn-primary" onClick={this.saveSettings}>Save</button></div>
                <ul>
                    <li key={Math.random()}>
                        <p>Artist Like Weight</p>
                        <input id="artistLikeWeight" type="range" min="1" max="10"  step="1" defaultValue={artistLikeWeight} />
                    </li>
                    <li  key={Math.random()}>
                        <p>Song Like Weight</p>
                        <input id="songLikeWeight" type="range" min="1" max="10"  step="1" defaultValue={songLikeWeight}/>
                    </li>
                    <li key={Math.random()}>
                        <p>Artist Newness Weight</p>
                        <input id="artistNewnessWeight" type="range" min="1" max="10"  step="1" defaultValue={artistNewnessWeight}/>
                    </li>
                    <li key={Math.random()}>
                        <p>Song Newness Weight</p>
                        <input id="songNewnessWeight" type="range" min="1" max="10" step="1" defaultValue={songNewnessWeight}/>
                    </li>
                    <li key={Math.random()}>
                        <p>Minimum number of days before repeating a song</p>
                        <input id="min_days_between_play" type="number" defaultValue={min_days_between_play}/>
                    </li>
                    <div className="stack">
                        <li key={Math.random()}>
                            <p>Minimum DBPS</p>
                            <input id="minDB" type="number" defaultValue={minDB}/>
                        </li>
                        <li key={Math.random()}>
                            <p>Maximum DBPS</p>
                            <input id="maxDB" type="number" defaultValue={maxDB}/>
                        </li>
                    </div>
                    <li key="genreslist" className="genre-list-container">
                        <p>Allowable Genres</p>
                        <div className="genre-list stack">
                        {(genres || []).map((item) => {
                                return <p key={Math.random()}><input name="genre" id={item} type="checkbox" defaultChecked={allowedGenres.includes(item)}/>{item}</p>
                            })
                        }    
                        </div>
                    </li>
                    <li key="languageslist" className="language-list-container">
                        <p>Allowable Languages</p>
                        <div className="language-list stack">
                            {(languages || []).map((item) => {
                                    return <p key={Math.random()}><input name="language" id={item} type="checkbox" defaultChecked={(allowedLanguages||["English","None"]).includes(item)}/>{item}</p>
                                })
                            }  
            

                        </div>
                    </li>    
                </ul>                
                
            </div>
        )
    }
}
export default React.memo(Settings);