import React, { setGlobal } from 'reactn';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import VideoComponent from './VideoComponent.js';
import ApiHelper from './ApiHelper.js';
import Settings from './Settings.js';
import ListVideo from './ListVideo.js';
import ListPlaylist from './ListPlaylist.js';
import ListTag from './ListTag.js';
import { DataStore,Predicates } from "@aws-amplify/datastore";
import { Video, UserSettings } from "../models";
import Header from './Header.js'
import { Auth } from '@aws-amplify/auth';
import _ from 'lodash';
const delay = ms => new Promise(res => setTimeout(res, ms));
const api = new ApiHelper();
class Player extends React.Component{
    state = {
        fetching: false,
        display_menu: false
    }
    
    getUniqueBy = (arr, prop) => {
        const myset = new Set();
        return arr.filter(o => !myset.has(o[prop]) && myset.add(o[prop]));
    };
    showMenu = () => {
        let active = this.state.display_menu;        
        this.setState({
            display_menu: !active            
        })
    }
    
    fetchVideos = async(reset_videos, resume) => {

        if (!this.state.fetching || resume) {
            this.setState({
                fetching: true
            });
            try {
              if (!this.global.other_videos || this.global.other_videos.filter(function(el){
                    return !el.played
                }).length < 10 || reset_videos) {      
                
                let new_videos = await api.getVideos(this.global.user, this.global, reset_videos, resume);  
                if(new_videos.name && new_videos.name.includes('Error'))  {
                    throw new_videos;
                }            
                if (new_videos.length > 0) {
                    if(reset_videos){
                        let old_videos = this.global.other_videos.filter(function(el){
                            return !el.played
                        });
                        old_videos.forEach(async el => {
                            DataStore.delete(el);
                            await delay(1000);
                        })
                    }
                    new_videos.forEach(async el => {      
                        el.played = false;
                        el.currentSong = false;                        
                        DataStore.save(
                            new Video(el)
                        );
                        await delay(1000);
                    });
      
                    this.setState({
                        fetching: false
                    })
                }
                else {      
                    if (this.global.playing_playlist) {
                        let playing_playlist = null;
                        if (this.global.playing_playlist.hasOwnProperty('fk_seed_id')) {
                            let auto_playlists = this.global.auto_playlists;
                            auto_playlists.forEach(function(el) {
                                el.playing = false;            
                            })
                            setGlobal({ 
                                auto_playlists: auto_playlists, 
                                playing_playlist: playing_playlist 
                            })
                        }
                        else if (this.global.playing_playlist.hasOwnProperty('genres')){
                            let genre_playlists = this.global.genre_playlists;
                            genre_playlists.forEach(function(el) {
                                el.playing = false;        
                            })
                            setGlobal({ 
                                genre_playlists: genre_playlists, 
                                playing_playlist: playing_playlist 
                            })
                        }                    
                        else {
                           
                                let artist_playlists = this.global.artist_playlists;
                                artist_playlists.forEach(function(el) {
                                    el.playing = false;      
                                })
                                setGlobal({ 
                                    artist_playlists: artist_playlists, 
                                    playing_playlist: playing_playlist 
                                })
                            
                        }
                    }
                    else if (this.global.playing_tag) {
                        let playing_tag = null;                        
                        let tags = this.global.tags;
                        tags.forEach(function(el) {
                            el.playing = false;        
                        })
                        setGlobal({ 
                            tags: tags,
                            playing_tag : playing_tag
                        })
                        
                    }
                    else if (this.global.selected_genres){                        
                        let settings = this.global.settings;
                        DataStore.save(
                            UserSettings.copyOf(settings, updated => {
                                updated.selected_genres = [];
                            })
                        );                                                
                    } else(
                        
                            alert("No available videos - please check your settings.")
                        
                    )     
                }
                this.setState({
                    fetching: false
                })     
            }
            
            } catch (error) {
                console.log(error);
                await delay(30000);
                this.setState({
                    fetching: false
                })                
                this.fetchVideos(reset_videos, true);
            }
            let all_videos = await DataStore.query(Video,Predicates.ALL,{sort: (s) => s.createdOn.ASCENDING});    
            let videos = this.getUniqueBy(all_videos, 'fk_video_id');        
            
            let selected_ids = videos.map(v => v.id);        
            all_videos.filter(function(el){
                return !selected_ids.includes(el.id)
            }).forEach(async el => {
                DataStore.delete(el);
            })
            this.setState({
                fetching: false
            }) 
            let other_current_song = await DataStore.query(Video, (e) =>
                e.currentSong.eq(true), {sort: (s) => s.createdOn.ASCENDING}
            );  
            if (other_current_song.length === 0){
                this.changeVideo()
            }
        }
    
        
            
    }
  
    changeVideo = async() => { 
             
        let other_current_song = await DataStore.query(Video, (e) =>
            e.currentSong.eq(true), {sort: (s) => s.createdOn.ASCENDING}
        );  
        
        if(!this.global.other_videos){
            await delay(5000);
            this.fetchVideos();
            return;
        }
        let videos = this.global.other_videos.filter(function(el){
            return !el.played
        });
        if (videos.length === 0) {
            await delay(5000);
            this.fetchVideos();
            return;
        }  
        let current_song = videos.shift();
        
        while (current_song.video_ban === 'true' || current_song.artist_ban === 'true') {            
            DataStore.save(
                Video.copyOf(current_song, updated => {
                    updated.currentSong = false;
                    updated.played = true;
                })
            );            
            current_song = videos.shift();            
        }
        DataStore.save(
            Video.copyOf(current_song, updated => {
                updated.currentSong = true;
            })
        );       
                        
        
        other_current_song.forEach(async function(el) {
            DataStore.save(
                Video.copyOf(el, updated => {
                    updated.currentSong = false;
                    updated.played = true;
                })
            );
        }); 
        
            
        let played_videos = await DataStore.query(Video, (e) =>
            e.played.eq(true), {sort: (s) => s.createdOn.ASCENDING}
        )
                
        while (played_videos.length > 10) {
            let video_to_delete = played_videos.shift();
            DataStore.delete(video_to_delete);
        }      
        
        this.fetchVideos();
            

       
    }

    signOut = async() => {
        try {
            await DataStore.clear();
            Auth.signOut();
            window.history.go("/");
        }
        catch (error) {
            await delay(1000)
            this.signOut()
        }
    }
    
    updateLists = async(update_video) => { 
        
        try{
            let other_videos = await DataStore.query(Video, (c) => c.currentSong.eq(false), {sort: (s) => s.createdOn.ASCENDING});
            let videos = this.getUniqueBy(other_videos, 'fk_video_id');        
            
            let selected_ids = videos.map(v => v.id);        
            other_videos.filter(function(el){
                return !selected_ids.includes(el.id)
            }).forEach(async el => {
                DataStore.delete(el);
            })
            other_videos = await DataStore.query(Video, (c) => c.currentSong.eq(false), {sort: (s) => s.createdOn.ASCENDING});
            let current_video = await DataStore.query(Video, (c) => c.currentSong.eq(true), {sort: (s) => s.createdOn.ASCENDING});
            current_video = current_video.pop();        
            if (this.global.playing_video && _.isEqual(current_video,this.global.playing_video)){
                setGlobal({             
                    "other_videos" : other_videos
                });
            }
            else {
                setGlobal({             
                    "other_videos" : other_videos,
                    "playing_video" : current_video
                });
            }
            
        } catch(error){
            console.log(error);
        }
             
             
    }    
    
    componentDidMount = async() => { 
        const videoSubscription = DataStore.observeQuery(Video).subscribe(msg => {
            this.updateLists(msg);
        });
        while(!this.global.user){
            await delay(1000);
        }
        this.updateLists().then(()=>{
            if(!this.global.playing_video){
                this.changeVideo();
            }
            else{
                this.fetchVideos(); 
            }
                      
        })           
         
                
    } 
    render(){       
        
        return (
            <div className="container"><Header user={this.global.user} video={this.global.playing_video} changeVideo={this.changeVideo}/>
            <div className="content">

                <button className="menu"><i className="fa-solid fa-bars" onClick={this.showMenu}></i></button>
                <Tabs
                defaultActiveKey="playing-video"
                id="content-tabs"
                className={this.state.display_menu ? "show-menu" : "hide-menu"}
                variant='pills'
                onClick={this.showMenu}
                >
                    
                    <Tab eventKey="playing-video" title="Playing Video">  
                        {this.global.playing_video && <VideoComponent item={this.global.playing_video} changeVideo={this.changeVideo} all_artists={this.props.all_artists} />  }                   
                    </Tab>
                    <Tab eventKey="playing-next" title="Playing Next"> 
                        {this.global.other_videos && <ListVideo items={(this.global.other_videos).filter(function(el){return !el.played })} all_artists={this.props.all_artists} />  }                    
                    </Tab>
                    <Tab eventKey="playing-history" title="Recently Played"> 
                        { this.global.other_videos && <ListVideo items={(this.global.other_videos).filter(function(el){return el.played }).reverse() } all_artists={this.props.all_artists} />    }                   
                    </Tab>                    
                    <Tab eventKey="artist-playlists" title="Artist Playlists">
                        {this.global.artist_playlists && <ListPlaylist items={this.global.artist_playlists || []} type="artist" editable={true} fetchVideos={this.fetchVideos}/>}
                    </Tab>
                    <Tab eventKey="genre-playlists" title="Genre Playlists"> 
                        { this.global.genre_playlists && <ListPlaylist items={this.global.genre_playlists || []} type="genre" editable={true} fetchVideos={this.fetchVideos}/>}
                    </Tab>
                    <Tab eventKey="auto-playlists" title="Auto Playlists"> 
                        {this.global.auto_playlists && <ListPlaylist items={this.global.auto_playlists || []} type="auto" fetchVideos={this.fetchVideos}/> }                      
                    </Tab>
                    <Tab eventKey="tags" title="Tags"> 
                        <ListTag fetchVideos={this.fetchVideos}/>                      
                    </Tab>
                    <Tab eventKey="settings" title="Settings"> 
                        <Settings fetchVideos={this.fetchVideos}/>                       
                    </Tab>
                    <Tab     eventKey="signout"                   
                        title={                           
                                
                                <p                                   
                                    onClick={this.signOut.bind(this)}>
                                    Sign Out
                                </p>
                            
                        }>                        
                    </Tab>                    
                </Tabs>
                
            </div>
            </div>
        )
    }
}
export default Player;