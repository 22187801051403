import React from 'reactn'; 
import Reactions from './Reactions.js'
class Header extends React.Component{
    render(){
        return (
            <div className="row header">
                <div className="col logo">
                    <img src="icons/logo_fill.png" alt="This Song Sucks" className="logo"/>
                    <h1>This Song Sucks</h1>                    
                </div>
                <div className="col">
                    {this.props.video && <Reactions video={this.props.video}  changeVideo={this.props.changeVideo}/>  }                  
                </div>
                <div className="col">
                    <h1>{this.props.video ? this.props.video.youtube_name : ""}</h1>                    
                </div>
            </div>
        )
    }
}
export default Header;