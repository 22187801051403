import React from 'reactn';
import YouTube from 'react-youtube';
import Reactions from './Reactions.js';
import SelectSearch from 'react-select-search';
import { DataStore } from "@aws-amplify/datastore";
import 'react-select-search/style.css'
import { Video } from "../models";
import ApiHelper from './ApiHelper.js';
import {matchSorter} from 'match-sorter';
const api = new ApiHelper();

class VideoComponent extends React.Component{
    state = {
          active: false,
          show_video: true
    }
    opts = {
        height: '390',
        width: '640',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 1,
          playsinline: 1,
          modestbranding: 1,
          enablejsapi: 1,
          origin: "https://www.thissongsucks.com"
        },
      };
    toggleVideoModal = () => {
        let active = this.state.active;
        console.log(this)
        this.setState({
            active: !active
        })
    }
    toggleVideo = () => {
        let active = this.state.show_video;
        console.log(this)
        this.setState({
            show_video: !active
        })
    }
    setArtist = async(artist) => {
        api.setArtist(this.props.item, artist);
        let artist_name = this.global.all_artists.find(obj => {
            return obj.value === artist
        })
        await DataStore.save(
            Video.copyOf(this.props.item, updated => {
              updated.youtube_name = artist_name.name;
              updated.fk_artist_id = artist;
            })
        );           
    }
    logActivity = async(activity) => {        
        api.logActivity(this.global.user, this.props.item, activity);
        let activity_map = {
            'like' : 'video_like',
            'ban' : 'video_ban',
            'unlike': 'video_like',
            'unban' : 'video_ban',
            'like_artist' : 'artist_like',
            'ban_artist' : 'artist_ban',
            'unlike_artist': 'artist_like',
            'unban_artist' : 'artist_ban',
            'globally_ban': 'video_ban'
        }
        let activity_status = !activity.includes('un')
        if (activity_map.hasOwnProperty(activity)){
            await DataStore.save(
                Video.copyOf(this.props.item, updated => {
                    updated[activity_map[activity]] = activity_status.toString();              
                })
            ); 
        }
        
        if(this.props.item.currentSong && (activity.includes('ban') || activity.includes('skip'))){
            let yt = this.YouTube;
            try {
                yt.internalPlayer.stopVideo();
            }
            catch(error){
                console.log(error);
            }
            this.props.changeVideo();
        }          

    }
    verifyPlaying = (event) =>{
        event.target.playVideo();

    }
    onPlayerError(state, event) {
        let yt = this.YouTube;
        
        if (yt.props.videoId && yt.props.videoId === state.props.item.youtube_id && !state.props.item.video_ban === "true") {
          this.logActivity('globally_ban');              
        }    
    }      
    onPlayerStateChange(state, event) {   
        let yt = this.YouTube;     
        if (event.data === 0) {
          console.log("Scheduling logging of 'finish' event..");
          this.logActivity('finish')
          console.log("Starting to pull next video..");
          this.props.changeVideo();    
        }
        else if ([-1, 5].includes(event.data) && event.target.getCurrentTime() === 0) {
            yt.internalPlayer.playVideo();    
        } 
        else if (event.target.getCurrentTime() >= event.target.getDuration() -1){
            yt.internalPlayer.stopVideo();
            this.props.changeVideo();
            setTimeout(function(yt){
                yt.internalPlayer.playVideo();
            },5000,yt)  
        }   
    }    
    render(){
        return (
            <div className="video-container" key={this.props.item.fk_video_id} >
                <div className={this.state.show_video ? "video-player wide" : "video-player tall" }> 
                { this.props.item.currentSong && this.props.item && this.props.item.fk_video_id ? 
                    <div className="video-div">
                        <button className="video-display-toggle" onClick={this.toggleVideo}>{this.state.show_video ? "Hide Video" : "Show Video" }</button>
                        <div className={this.state.show_video ? "show-video" : "hide-video" }> 
                            <YouTube ref={YouTube => this.YouTube = YouTube} opts={this.opts} videoId={this.props.item.youtube_id} onReady={this.verifyPlaying} onStateChange={this.onPlayerStateChange.bind(this, this)} onPlay={this.logActivity.bind(this, 'play')} onError={this.onPlayerError.bind(this, this)}  /> 
                        </div>
                    </div>
                :
                    <div className="video-modal-image">
                        <img src={`https://i.ytimg.com/vi/${this.props.item.youtube_id}/default.jpg`} alt={`youtube thumbnail for ${this.props.item.youtube_id}`} />
                        <Reactions video={this.props.item}/> 
                    </div>
                }        
                    <div className="video-details"> 
                        
                  
                        
                            <div>
                                <div className="like-checks">
                                    {this.props.item.video_ban === 'true' ? <img src="icons/ban.png" onClick={this.logActivity.bind(this, 'unban')} alt="banned_video" /> : <div/> } 
                                    {this.props.item.video_like === 'true' ? <img src="icons/like.png"  onClick={this.logActivity.bind(this,'unlike')} alt="liked_video"/> : <div/> } 
                                    {this.props.item.artist_like === 'true' ? <img src="icons/like-artist.png"  onClick={this.logActivity.bind(this,'unlike_artist')} alt="liked_artist"/> : <div/> } 
                                    {this.props.item.artist_ban === 'true' ? <img src="icons/ban-artist.png"  onClick={this.logActivity.bind(this, 'unban_artist')} alt="banned_artist"/> : <div/> }
                                </div>
                                { this.props.item.youtube_name ? <p>{this.props.item.youtube_name}</p> : <span/>}                                 
                                { this.props.item.track ? <p>{this.props.item.track}</p> : <p>{this.props.item.title}</p>}                                       
                                
                                { this.props.item.spotify_genres ? <p>{this.props.item.spotify_genres}</p> : <span/>}  
                                <p className="view-stat">You've played this video {this.props.item.video_views || 0} times. { this.props.item.video_last_viewed ? "Last played on " + this.props.item.video_last_viewed  + "." : <span/>} </p>                      
                                { 
                                    this.props.item.youtube_name 
                                        ? <p className="view-stat">You've played this artist {this.props.item.artist_views || 0} times. <span> { this.props.item.artist_last_viewed ? "Last played on " + this.props.item.artist_last_viewed  + "." : <span/> } </span></p>: <span/>                                    
                                    
                                } 
                                <p>Language: {this.props.item.language}</p>
                                <p>DBPS: {this.props.item.dbps}</p>
                                <p>Tags: {this.props.item.youtube_tags}</p>
                                <p><a href={"https://www.youtube.com/watch?v=" + this.props.item.youtube_id }>View on Youtube</a>  ( {this.props.item.youtube_id} )  </p>
                                { this.global.user && this.global.user.isAdmin ? 
                                    <div className="search" >
                                        <SelectSearch 
                                            options={this.props.all_artists || []}
                                            search
                                            placeholder="Set the artist..."
                                            onChange={this.setArtist.bind(this)}
                                            fuzzySearch
                                            filterOptions={[(options) => {  
                                                                                            
                                                const filter = options || []
                                                return filter.slice(0, 100);
                                            }]}
                                            getOptions={inputValue => {
                                                if (this.props.all_artists) {
                                                    return matchSorter(this.props.all_artists, inputValue, {keys: ['name']})
                                                }else {
                                                    return []
                                                }
                                              }}
                                            closeOnSelect
                                            printOptions="on-focus"
                                        />                  
                                    </div> : <div/> 
                                }
                        
                        </div>
                    </div>
                </div>
            </div>        
        )
    }
}
export default React.memo(VideoComponent);