const axios = require('axios');
class ApiHelper {

    BASE_URL = "https://api.thissongsucks.com";
    getSettings = async(user) => {
        let results = await axios.post('https://api.thissongsucks.com/tss-api-settings', {
            "user_id": user.id
        })
        return results.data;
    }
    setSettings = async(user, settings) => {
        let results = await axios.post(`${this.BASE_URL}/tss-api-settings`, {
            "user_id": user.id,
            "artistLikeWeight": settings.artistLikeWeight || 5,
            "songLikeWeight": settings.songLikeWeight || 5,
            "artistNewnessWeight": settings.artistNewnessWeight || 5,
            "songNewnessWeight": settings.songNewnessWeight || 5,
            "allowedGenres": settings.allowedGenres,
            "allowedLanguages": settings.allowedLanguages,
            "min_days_between_play": settings.min_days_between_play || 120,
            "minDB": settings.minDB || -15,
            "maxDB": settings.maxDB || -10
        })
        return results.data
    }
    getVideos = async(user, global, reset_videos, resume) =>{
        try {
            let results = await axios.post(`${this.BASE_URL}/tss-api-get-random-video`, {            
                'user_id': user.id,
                'selected_genres': JSON.stringify(global.playing_genres),
                'selected_videos': JSON.stringify((global.other_videos|| []).concat([global.playing_video || {}]).map(function(a) { return { 
                "fk_video_id": a.fk_video_id }; })),
                'selected_playlist': JSON.stringify(global.playing_playlist),
                'minDB': global.settings.minDB || -15,
                'maxDB': global.settings.maxDB || -10,
                'playingTag': global.playing_tag,
                'reset_videos': reset_videos,
                'resume': resume        
            });
            return results.data;
        } catch (error) {
            return error;
        }
    }
    getArtistPlaylists = async(user) => {
        try {
            let results = await axios.get(`${this.BASE_URL}/tss-api-playlists`, {
                'params': {
                'user_id': user.id
                }
            })
            let playlist = results.data || [];
            let uniqueplaylists = [...new Set(playlist.map(item => item.id))];
            let playlists = []
            uniqueplaylists.forEach(function(el) {
                playlists.push({
                    "id": el,
                    "name": playlist.find(element => element.id === el).name,
                    "youtube_id": playlist.find(element => element.id === el).youtube_id,
                    "artists": playlist.filter(element => element.id === el && element.fk_artist_id)
                })
            })
            return playlists;
        } catch (error) {
            this.getArtistPlaylists(user)
        }
    }
    getGenrePlaylists = async(user) => {
        try {
        let results = await axios.get(`${this.BASE_URL}/tss-api-genre-playlists`, {
            'params': {
              'user_id': user.id
            }
        })
        let playlist = results.data || [];
        let uniqueplaylists = [...new Set(playlist.map(item => item.id))];
        let playlists = []
        uniqueplaylists.forEach(function(el) {
            playlists.push({
                "id": el,
                "name": playlist.find(element => element.id === el).name,
                "youtube_id": playlist.find(element => element.id === el).youtube_id,
                "genres": playlist.filter(element => element.id === el && element.fk_genre_id)
            })
        })
        return playlists;
    } catch (error) {
        this.getGenrePlaylists(user)
    }
    }
    getAutoPlaylists = async(user) => {
        try{
        let results = await axios.get(`${this.BASE_URL}/tss-api-autoplaylists`, {
            'params': {
              'user_id': user.id
            }
        })
        let playlist = results.data || []
        let uniqueplaylists = [...new Set(playlist.map(item => item.fk_seed_id))];
        let playlists = []
        uniqueplaylists.forEach(function(el) {
            playlists.push({
                "fk_seed_id": el,
                "youtube_id": playlist.find(element => element.fk_seed_id === el).youtube_id,
                "youtube_name": playlist.find(element => element.fk_seed_id === el).youtube_name
            })
        })
        return playlists;
    } catch (error) {
        this.getAutoPlaylists(user)
    }
    }
    getGenres = async(user) => {
        try {
            let results = await axios.get(`${this.BASE_URL}/tss-api-get-spotify-genres`, {
                'params': {
                    'user_id': user.id
                }
            });
            return results.data;
        } catch (error) {
            this.getGenres(user)
        }
    }
    getArtists = async() => {
        try {
            let results = await axios.get('https://api.thissongsucks.com/tss-api-get-all-artists')
            return results.data;
        } catch (error) {
            this.getArtists()
        }
    }
    logActivity(user, video, activity){
        let result = axios.post(`${this.BASE_URL}/tss-api-log-activity`, {
            "user_id": user.id,
            "fk_video_id": video.fk_video_id,
            "activity_type": activity,
            "artist_id": video.artist_id,
            "fk_artist_id": video.fk_artist_id
        });
        return result;
    }
    tagVideo(video, tag, add_tag){
        let result = axios.post(`${this.BASE_URL}/tss-api-tag-video`, {            
            "fk_video_id": video.fk_video_id,
            "tag": tag,
            "add": add_tag
        });
        return result.data;
    }
    setArtist(video, artist){
        axios.post(`${this.BASE_URL}/tss-api-set-artist`, {
            "fk_video_id": video.fk_video_id,
            "fk_artist_id": artist
        });
    }
    savePlaylist= async(user, playlist, type, to_delete) =>{
        if (type === "artist"){
            let results = await axios.post(`${this.BASE_URL}/tss-api-playlists`, {
                "playlist": playlist,
                "user_id": user.id,
                "delete": to_delete
            });
            return results.data;
        } else {
            let results = await axios.post(`${this.BASE_URL}/tss-api-genre-playlists`, {
                "playlist": playlist,
                "user_id": user.id,
                "delete": to_delete
            });
            return results.data;
        }
    }

    

}
export default ApiHelper